// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll(同一ページ内)
jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {    
    let targetId = jQuery(this.hash);
    
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      let target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) +']');

      // ターゲット先へスクロール移動
      if (target.length) {
        scrollHashPos(target);

        return false;
      }
    }
  });
});

//別ページのアンカーリンクへ移動
$(window).on('load', function() {
	// ページのURLを取得
	const url = $(location).attr('href'),
	// headerの高さを取得してそれに30px追加した値をheaderHeightに代入
	headerHeight = $('header').outerHeight() + 30;

	// urlに「#」が含まれていれば
	if (url.indexOf("#") != -1) {
    // urlを#で分割して配列に格納
		let anchor = url.split("#");
		
		// 分割した最後の文字列（#◯◯の部分）をtargetに代入
		target = $('#' + anchor[anchor.length - 1]);

    // ターゲット先へスクロール移動
    if (target.length) {
      //0.5秒後に実行
      setTimeout(function(){
        scrollHashPos(target);
      },1000);

      return false;
    }
	}
});

function scrollHashPos(target) {
  var headerHeight = $("header").height();        //ヘッダーの高さ
  var headerTopPos = $(".l-header").css(['top']); //ヘッダーのtop値(上余白)
  var targetOffset = target.offset().top;

  let smoothScrollAjust = parseInt(headerHeight) + parseInt(headerTopPos.top); //位置調整(単位は削除)
console.log("targetOffset:" + targetOffset);
console.log("smoothScrollAjust:" + smoothScrollAjust);
  if (targetOffset > smoothScrollAjust) {
    targetOffset = targetOffset - smoothScrollAjust;
  }

  jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'swing');
}



// slide btn
$(function(){
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      $(this).toggleClass('is-active');
      $(element).next(".js-slidePanel").slideToggle(300);
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// current
$(function() {
  var gNavItem = $("#gnav .js-gnavItem");

  var local_path_arr = $(location).attr('pathname').split('/');
  var local_dir = local_path_arr[1];

  gNavItem.each(function(){
    var href_path = $(this).attr("href");
    var href_path_arr = href_path.split('/');
    var href_dir = href_path_arr[1];
    
    if(href_dir == local_dir){
      $(this).addClass('is-current');
    } else {
      $(this).removeClass('is-current');
    }
  });
});


// header & mv & parts scroll settings
function handle(event) {
  event.preventDefault();
}

$(window).on('load scroll resize', function(){  
  let winW = $(window).width();
  let winscrW = window.innerWidth;        //スクロールバーを含む幅 
  let winH = $(window).height();  //ウィンドウの高さ

  let mc = $('#maincontent');
  let hd = $('#header');
  let contentTop = $('#hero');
  let gnav = $('#gnavfix');
  let fixlogo = $('#fixed-logo');
  let dopen = $('#js-drawerOpen');
  let fixlogoH = fixlogo.outerHeight();
  let scroll = $(window).scrollTop();
  let hero = $('#hero');
  let moveleft = $('.js-moveleft');
  let drawwave = $('.js-drawwave');
  
  let headerPosTop = contentTop.outerHeight();  //ヘッダーの初期位置

  //ヘッダーメニュー制御
  if ( scroll < headerPosTop && winscrW > 799 ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
    fixlogo.removeClass('js-isscroll');
    fixlogo.addClass('js-noscroll');
  } else {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
    fixlogo.removeClass('js-noscroll');
    fixlogo.addClass('js-isscroll');
  }

  //ロゴ＆ドロワーオープンボタン制御
  if ( scroll != 0 ) {
    dopen.removeClass('js-init');
  } else {
  }

  //メインビジュアル制御
  if ( scroll != 0 ) {
    hero.removeClass('js-init');
    hero.removeClass('js-noscroll');
    hero.addClass('js-isscroll');
  }
  
  //要素左スライド
  moveleft.each(function(){
    let mlTop = $(this).offset().top;

    // 要素が画面の上から40%に来た時に動く
    let moveleftPos = mlTop - (winH * 0.6);

    if (scroll > moveleftPos){
      $(this).addClass("js-ismove");
    }
  });
  
  
  //長方形描画
  drawwave.each(function(){
    let dwTop = $(this).offset().top;

    // 要素が画面の上から10%に来た時に動く
    let drawwavePos = dwTop - (winH * 0.9);

    if (scroll > drawwavePos){
      $(this).addClass("-do");
    }
  });
});


// front page mv setting
$(window).on('load', function(){  
  let wrap = $('#wrap');
  let mc = $('#maincontent');
  let scroll = $(window).scrollTop();

  //メインビジュアル制御
  if ( scroll == 0 ) {
    mc.addClass('js-init');

    if ( wrap.hasClass("-front") ) {  //トップページのみ、スクロール制御(MV全画面表示中は、スクロール無効化)
      document.addEventListener('touchmove', handle, { passive: false }); 
      document.addEventListener('mousewheel', handle, { passive: false });
    }
  }
  
  if (mc.hasClass("js-init")) {
    let hash = $(location).attr('hash');
    
    if (hash.indexOf("#") >= 0) {
      scrollTrigger();

      //0.5秒後に実行
      setTimeout(function(){
        scrollHashPos();
      },500);
    }
    
    if ( wrap.hasClass("-front") ) {  //トップページのみ、スクロール制御(MV全画面表示中は、スクロール無効化)
      // マウス操作
      document.querySelector("body").addEventListener('wheel', scrollTrigger);
      document.querySelector("body").addEventListener('click', scrollTrigger);
    
      // タッチ・スワイプ操作
      document.querySelector("body").addEventListener('touchstart', scrollTrigger);
    }
  }
});

function scrollTrigger() {
  let dopen = $('#js-drawerOpen');
  let hero = $('#hero');
  let mc = $('#maincontent');

  if (mc.hasClass("js-init")) {
    $(window).scrollTop(0);
    hero.removeClass('js-init');
    hero.addClass('js-isscroll');
    
    //0.75秒後に実行
    setTimeout(function(){
      mc.removeClass('js-init');
      dopen.removeClass('js-init');
      document.removeEventListener('touchmove', handle, { passive: false });    //スクロール制御(スクロール有効化)
      document.removeEventListener('mousewheel', handle, { passive: false });    //スクロール制御(スクロール有効化)
    },750);
  }
}


// バーガーメニュー開閉
function drawerOpen() {
  $("#header").removeClass('js-isclose');
  $("#js-drawerOpen").removeClass('js-isclose');
  $("#drawer-wrap").removeClass('js-isclose');
  $("#drawer").removeClass('js-isclose');
  $("#gnav").removeClass('js-isclose');
  $("#fixed-logo").removeClass('js-isclose');
  $("#header").addClass('js-isopen');
  $("#js-drawerOpen").addClass('js-isopen');
  $("#drawer-wrap").addClass('js-isopen');
  $("#drawer").addClass('js-isopen');
  $("#gnav").addClass('js-isopen');
  $("#fixed-logo").addClass('js-isopen');
}
function drawerClose() {
  $("#header").removeClass('js-isopen');
  $("#js-drawerOpen").removeClass('js-isopen');
  $("#drawer-wrap").removeClass('js-isopen');
  $("#drawer").removeClass('js-isopen');
  $("#gnav").removeClass('js-isopen');
  $("#fixed-logo").removeClass('js-isopen');
  $("#header").addClass('js-isclose');
  $("#js-drawerOpen").addClass('js-isclose');
  $("#drawer-wrap").addClass('js-isclose');
  $("#drawer").addClass('js-isclose');
  $("#gnav").addClass('js-isclose');
  $("#fixed-logo").addClass('js-isclose');
}

$(function(){
  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('js-isopen')) {
      drawerClose();
    } else {
      drawerOpen();
    }
  });
  
  $(".js-gnavItem").click(function() {
    if ($("#js-drawerOpen").hasClass('js-isopen')) {
      drawerClose();
    }
  });
});


// resize settings
$(window).on('resize', function(){
  let winW = $(window).width();
  let winscrW = window.innerWidth;  /* スクロールバーを含む幅 */

  if ( winscrW >= 800 ) {
    if ($("#header").hasClass('js-isopen')) {
      drawerClose();
    }
  }
});


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      var str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}


///// 指定行数で以下略にする /////
// 省略対象のclassを指定
var omit = document.getElementsByClassName('js-omit');
$(window).on('load resize', function () {
  //表示する行数を取得(HTMLの「data-omitline-max」属性に行数を数値で指定する)
  lineNum = $('.js-omit').data('omitline'); // 元の文章をdata-origintextに退避

  omit_init(); // 文章を省略する処理。

  omit_sentence();
});


//文字省略の初期設定
function omit_init() {
  for (var i = 0; i < omit.length; i++) {
    if (omit[i].dataset.origintext === undefined) {
      var sentence = omit[i].textContent;
      omit[i].dataset.origintext = sentence;
    }
  }
}


//文字省略
//line - 表示する行数(ここで数値した行数以上は、省略する)
function omit_sentence() {
  var line = new Array();
  var winscrW = window.innerWidth;
  /* スクロールバーを含む幅 */

  var dispMode = "pc";

  if (winscrW <= 800) {
    dispMode = "sp";
  } // 対象の文章を1文字ずつspanで区切る


  for (var i = 0; i < omit.length; i++) {
    line[i] = omit[i].getAttribute('data-omitline-max'); //表示する行数

    if (omit[i].hasAttribute('data-omit-target')) {
      //data-omit-target属性がある場合のみ、対象ごとに独自処理
      omitTarget = omit[i].getAttribute('data-omit-target');
      /*
       * トップページのお客様の声の文章用
       * 375px以下→1列なので変更なし、420px以下→2列で小さいので2行減らす、480px以下→2列で多少小さいので1行減らす
       */

      if (omitTarget == 'toppage-rev-txt') {
        if (winscrW <= 700) {
          line[i] = line[i] - 3; //3行減らす
        } else if (winscrW <= 1000) {
          line[i] = line[i] - 2; //2行減らす
        }
      }
    }

    omit[i].innerHTML = divide_sentence(omit[i].dataset.origintext);
  } // 区切った文字の行数(座標)を調べる


  for (var i = 0; i < omit.length; i++) {
    var s = omit[i].getElementsByTagName('span'); // 省略する位置を調べる

    var omitIndex = check_character_row_count(s, line[i]); // 省略した文章を反映

    var sentence = omit[i].dataset.origintext;

    if (omitIndex !== undefined) {
      sentence = sentence.slice(0, omitIndex) + '…';
    }

    omit[i].textContent = sentence;
  }
}

  
//文章を1文字ずつspanで括って返す
//text - 分割する文字列
function divide_sentence(text) {
  var textArr = text.split('');
  var divideText = '';

  for (var i = 0; i < textArr.length; i++) {
    divideText += '<span>' + textArr[i] + '</span>';
  }

  divideText += '<span>' + '…' + '</span>';
  return divideText;
}


//省略する位置を調べて返す
//@param {object} text - spanで区切った文字群
//@param {number} line - 省略する行数
function check_character_row_count(text, line) {
  var lineCount = 0;
  var beforeTop = text[0].getBoundingClientRect().top - 1;
  var omitIndex = undefined;

  for (var i = 0; i < text.length - 1; i++) {
    // 文章の最後に確認用の省略文字が入っているので、最後の文字はチェックから除外
    var currentTop = text[i].getBoundingClientRect().top; // 今回の文字の座標が前回の文字の座標より先に進んでいる時

    if (currentTop > beforeTop) {
      beforeTop = currentTop;
      lineCount++;
    } // 調べている文字が省略行数を過ぎた時


    if (lineCount > line) {
      // 省略の記号を入れるため、文字の幅から切り取り位置の調整
      var ellipsisWidth = text[text.length - 1].getBoundingClientRect().width + 1;
      var c = 0;
      var w = 0;

      do {
        c++;
        w += text[i - c].getBoundingClientRect().width;
      } while (w < ellipsisWidth);

      omitIndex = i - c;
      break;
    }
  }

  return omitIndex;
}
